import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { getAllOffersAndBids } from '../../_helpers/apiService';

const CommodityListing = ({ data }) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState({ commodity: '', location: '', type: '', status: '' });
  const [apiData, setApiData] = useState('');

  const filterData = (item) => {
    const searchTermMatch = item.commodity.toLowerCase().includes(searchTerm.toLowerCase());
    const commodityFilterMatch = filter.commodity === '' || item.commodity?.toLowerCase() === filter.commodity?.toLowerCase();
    const locationFilterMatch = filter.location === '' || item.location?.toLowerCase() === filter.location?.toLowerCase();
    const typeFilterMatch =
      filter.type === '' ||
      (item.commodity_order[0]?.type?.toLowerCase() === "super_bid" ? "BIDS" : item.commodity_order[0]?.type?.toLowerCase()) === filter.type ||
      (item.commodity_order[0]?.type?.toLowerCase() === "super_offer" ? "OFFERS" : item.commodity_order[0]?.type?.toLowerCase()) === filter.type;
    const statusFilterMatch = filter.status === '' || (item.commodity_order[0]?.status?.toLowerCase() === filter.status.toLowerCase());

    return searchTermMatch && commodityFilterMatch && locationFilterMatch && typeFilterMatch && statusFilterMatch;
  };

  const getAllCommodities = () => {
    const allCommodities = new Set();
    if (apiData.broker_bids)
    {
      apiData.broker_bids.forEach((bid) => {
        allCommodities.add(bid.commodity.toLowerCase());
      });
    }
    if (apiData.broker_offer)
    {
      apiData.broker_offer.forEach((offer) => {
        allCommodities.add(offer.commodity.toLowerCase());
      });
    }
    return Array.from(allCommodities);
  };

  const getAllLocations = () => {
    const allLocations = new Set();

    if (apiData.broker_bids)
    {
      apiData.broker_bids.forEach((bid) => {
        allLocations.add(bid.location);
      });
    }

    if (apiData.broker_offer)
    {
      apiData.broker_offer.forEach((offer) => {
        allLocations.add(offer.location);
      });
    }

    return Array.from(allLocations);
  };

  const getAllTypes = () => {
    const allTypes = new Set();

    if (apiData.broker_bids)
    {
      apiData.broker_bids.forEach((bid) => {
        const type = bid.commodity_order && bid.commodity_order.length > 0
          ? bid.commodity_order[0]?.type?.toLowerCase() === "super_bid"
            ? "BIDS"
            : ''
          : '';
        allTypes.add(type);
      });
    }

    if (apiData.broker_offer)
    {
      apiData.broker_offer.forEach((offer) => {
        const type = offer.commodity_order && offer.commodity_order.length > 0
          ? offer.commodity_order[0]?.type?.toLowerCase() === "super_offer"
            ? "OFFERS"
            : ''
          : '';
        allTypes.add(type);
      });
    }

    return Array.from(allTypes);
  };

  const getAllStatuses = () => {
    const allStatuses = new Set();

    if (apiData.broker_bids)
    {
      apiData.broker_bids.forEach((bid) => {
        const status = bid.commodity_order[0]?.status?.toLowerCase();
        allStatuses.add(status);
      });
    }

    if (apiData.broker_offer)
    {
      apiData.broker_offer.forEach((offer) => {
        const status = offer.commodity_order[0]?.status?.toLowerCase();
        allStatuses.add(status);
      });
    }

    return Array.from(allStatuses);
  };


  const filteredBrokerOfferData = apiData
    ? apiData?.broker_offer?.filter((item) => filterData(item))
    : [];

  const filteredBrokerBidData = apiData
    ? apiData?.broker_bids?.filter((item) => filterData(item))
    : [];

  useEffect(() => {
    dispatch(getAllOffersAndBids).then((response) => {
      setApiData(response);
    });
  }, []);

  function isBetween9to12 () {
    const currentDate = new Date();
    const currentHour = currentDate.getHours();
    const currentDay = currentDate.getDay();

    // Check if the current time is between 09:00 and 12:00 and it's not a weekend (Saturday(6) or Sunday(0))
    return currentHour >= 9 && currentHour < 12 && currentDay !== 0 && currentDay !== 6;
  }

  return (
    <div className="relative overflow-x-auto">
      <div className="p-4 bg-white dark:bg-gray-900">
        <h3 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-3xl dark:text-white">Market Watch<small className="ml-2 font-thin text-gray-500 dark:text-gray-400">Trading Session OPEN 09:00 to 12:00 (Except on Public Holidays & Weekends)</small></h3>
        <br />
        <div className="pb-4 bg-white dark:bg-gray-900 flex items-center">
          <label htmlFor="table-search" className="sr-only">Search</label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-5 h-5 text-gray-500 dark:text-gray-400" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd"></path>
              </svg>
            </div>
            <input
              type="text"
              className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              placeholder="Search for a commodity"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="relative ml-3">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-5 h-5 text-blue-500" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 12l-4.95 4.95L15 17l6-6-6-6 1.05-1.05L21 12zm-14 0l4.95-4.95L9 7l-6 6 6 6-1.05 1.05L3 12z" />
              </svg>
            </div>
            <select
              className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              onChange={(e) => setFilter({ ...filter, commodity: e.target.value })}
            >
              <option value="">Filter By Commodity</option>
              <option value="">All</option>
              {getAllCommodities().map((commodity) => (
                <option key={commodity} value={commodity}>
                  {commodity.replace(/_/g, ' ').toUpperCase()}
                </option>
              ))}
            </select>
          </div>

          <div className="relative ml-3">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-blue-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M21 12l-4.95 4.95L15 17l6-6-6-6 1.05-1.05L21 12zm-14 0l4.95-4.95L9 7l-6 6 6 6-1.05 1.05L3 12z" />
              </svg>
            </div>
            <select
              className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              onChange={(e) => setFilter({ ...filter, type: e.target.value })}
            >
              <option value="">Filter By Type</option>
              <option value="">All</option>
              {getAllTypes().map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>

          <div className="relative ml-3">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg className="w-5 h-5 text-blue-500" aria-hidden="true" fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 12l-4.95 4.95L15 17l6-6-6-6 1.05-1.05L21 12zm-14 0l4.95-4.95L9 7l-6 6 6 6-1.05 1.05L3 12z" />
              </svg>
            </div>
            <select
              className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              onChange={(e) => setFilter({ ...filter, status: e.target.value })}
            >
              <option value="">Filter By Status</option>
              <option value="">All</option>
              {getAllStatuses().map((status) => (
                <option key={status} value={status}>
                  {status.toUpperCase()}
                </option>
              ))}
            </select>
          </div>

          <div className="relative ml-3">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-blue-500"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M21 12l-4.95 4.95L15 17l6-6-6-6 1.05-1.05L21 12zm-14 0l4.95-4.95L9 7l-6 6 6 6-1.05 1.05L3 12z" />
              </svg>
            </div>
            <select
              className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-green-500 focus:border-green-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-green-500 dark:focus:border-green-500"
              onChange={(e) => setFilter({ ...filter, location: e.target.value })}
            >
              <option value="">Filter By Location</option>
              <option value="">All</option>
              {getAllLocations().map((location) => (
                <option key={location} value={location}>
                  {location}
                </option>
              ))}
            </select>
          </div>
        </div>

        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">Ref.</th>
              <th scope="col" className="px-6 py-3">Commodity</th>
              <th scope="col" className="px-6 py-3">Quality</th>
              <th scope="col" className="px-6 py-3">Type</th>
              <th scope="col" className="px-6 py-3">Unit</th>
              <th scope="col" className="px-6 py-3">Price(ZMW)</th>
              <th scope="col" className="px-6 py-3">Qty</th>
              <th scope="col" className="px-6 py-3">Location</th>
              <th scope="col" className="px-6 py-3">Delivery</th>
              <th scope="col" className="px-6 py-3">Conditions</th>
              <th scope="col" className="px-6 py-3">Status</th>
              <th scope="col" className="px-6 py-3">Date</th>
              <th scope="col" className="px-6 py-3">Action</th>
            </tr>
          </thead>
          <tbody>
            {filteredBrokerOfferData.map((item, index) => (
              <React.Fragment key={index}>
                {/* Main Offer Row */}
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item.commodity_order && item.commodity_order.length > 0 ? item.commodity_order[0].commodity_order_reference_no || '-' : '-'}
                  </th>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : ""}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.quality.toUpperCase()}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">OFFER</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">Mt</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item?.requested_unit_price || "---"}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.volume_on_offer}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.location}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.delivery}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.special_terms_and_conditions}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.commodity_order && item.commodity_order.length > 0 ? item.commodity_order[0].status.toUpperCase() || '-' : '-'}</td>
                  <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.date_to_be_placed_on_platform}</td>

                  {/* offer action button */}
                  <td className="px-6 py-4">
                    <button
                      onClick={() => isBetween9to12() && window.open("https://dev-dashboard-mz.zamace.co.zm/")}
                      className={`text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 ${!isBetween9to12() && 'cursor-not-allowed opacity-50'}`}
                      disabled={!isBetween9to12()}
                    >
                      Bid
                    </button>
                  </td>
                </tr>

                {/* Bids Rows for the Trade */}
                {item.broker_bids && item.broker_bids.length > 0 && item.broker_bids.map((bid, bidIndex) => (
                  <tr key={`bid-${bidIndex}`} className="bg-gray-100 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700">
                    <td scope='row' className='px-6 py-4'>
                      {bid.broker_bid_reference_no}
                    </td>
                    <td className="px-4 py-2">{bid.commodity ? bid?.commodity.replace(/_/g, ' ').toUpperCase() : ""}</td>
                    <td className="px-4 py-2">{bid.quality.toUpperCase()}</td>
                    <td className="px-4 py-2">BID</td>
                    <td className="px-4 py-2">Mt</td>
                    <td className="px-4 py-2">{bid?.bid || "---"}</td>
                    <td className="px-4 py-2">{bid.bid_volume}</td>
                    <td className="px-4 py-2">{bid.location}</td>
                    <td className="px-4 py-2">{bid.delivery}</td>
                    <td className="px-4 py-2">{bid.special_terms_and_conditions}</td>
                    <td className="px-4 py-2">{bid.status.toUpperCase()}</td>
                    <td className="px-6 py-2">{bid.broker_bid_date}</td>
                    <td className="px-6 py-4">
                      {/* Bid action button */}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}

            {filteredBrokerBidData.map((item, index) => (
              <React.Fragment key={index}>
                {/* Main Bid Row */}
                <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600">
                  <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {item.commodity_order && item.commodity_order.length > 0 ? item.commodity_order[0].commodity_order_reference_no || '-' : '-'}
                  </th>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.commodity ? item?.commodity.replace(/_/g, ' ').toUpperCase() : ""}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.quality.toUpperCase()}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">BID</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">Mt</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item?.requested_unit_price || "---"}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.volume}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.location}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.delivery}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.special_terms_and_conditions}</td>
                  <td className="px-4 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.commodity_order && item.commodity_order.length > 0 ? item.commodity_order[0].status.toUpperCase() || '-' : '-'}</td>
                  <td className="px-6 py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white">{item.date_to_be_placed_on_platform}</td>

                  {/* Bid action button */}
                  <td className="px-6 py-4">
                    <button
                      onClick={() => isBetween9to12() && window.open("https://dev-dashboard-mz.zamace.co.zm/")}
                      className={`text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:outline-none focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 ${!isBetween9to12() && 'cursor-not-allowed opacity-50'}`}
                      disabled={!isBetween9to12()}
                    >
                      Offer
                    </button>
                  </td>
                </tr>

                {/* Offers Rows for the Trade */}
                {item.broker_offers && item.broker_offers.length > 0 && item.broker_offers.map((offer, OfferIndex) => (
                  <tr key={`offer-${OfferIndex}`} className="bg-gray-100 dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-100 dark:hover:bg-gray-700">
                    <td scope='row' className='px-6 py-4'>
                      {offer.broker_offer_reference_no}
                    </td>
                    <td className="px-4 py-2">{offer.commodity ? offer?.commodity.replace(/_/g, ' ').toUpperCase() : ""}</td>
                    <td className="px-4 py-2">{offer.quality.toUpperCase()}</td>
                    <td className="px-4 py-2">OFFER</td>
                    <td className="px-4 py-2">Mt</td>
                    <td className="px-4 py-2">{offer?.offer || "---"}</td>
                    <td className="px-4 py-2">{offer.offer_volume}</td>
                    <td className="px-4 py-2">{offer.location}</td>
                    <td className="px-4 py-2">{offer.delivery}</td>
                    <td className="px-4 py-2">{offer.special_terms_and_conditions}</td>
                    <td className="px-4 py-2">{offer.status.toUpperCase()}</td>
                    <td className="px-6 py-2">{offer.broker_offer_date}</td>
                    <td className="px-6 py-4">
                      {/* Offer action button */}
                    </td>
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CommodityListing;
