import axios from 'axios';

const api = axios.create({
  baseURL: 'https://dev-api-mz.zamace.co.zm//api/v1/',
});

export const createAccount = async (account) => {
  try
  {
    const response = await api.post('/accounts/create', account);
    return response.data;
  } catch (error)
  {
    if (error.response && error.response.data && error.response.data.errors)
    {
      throw error.response.data.errors;
    } else
    {
      throw error;
    }
  }
};

export const loginAccount = async (account) => {
  try
  {
    const response = await api.post('/accounts/sign_in', account);
    return response.data;
  } catch (error)
  {
    if (error.response && error.response.data && error.response.data.errors)
    {
      throw error.response.data.errors;
    } else
    {
      throw error;
    }
  }
};

export const getAllOffersAndBids = async () => {
  try
  {
    // const response = await axios.get('http://localhost:4000/api/v1/website/ats/list_all_offers'); //Localhost
    const response = await axios.get('https://dev-api-mz.zamace.co.zm/api/v1/website/ats/list_all_offers'); //Dev
    return response.data.data;

  } catch (error)
  {
    if (error.response && error.response.data && error.response.data.errors)
    {
      throw error.response.data.errors;
    } else
    {
      throw error;
    }
  }
};


export const getAllRegisteredBrokers = async () => {
  try
  {
    const response = await axios.get('https://dev-api-mz.zamace.co.zm/api/v1/website/entities/active_verified/broker'); //Dev
    return response.data;
  } catch (error)
  {
    if (error.response && error.response.data && error.response.data.errors)
    {
      throw error.response.data.errors;
    } else
    {
      throw error;
    }
  }
};

export const getAllCertifiedWarehouses = async () => {
  try
  {
    const response = await axios.get('https://dev-api-mz.zamace.co.zm//api/v1/website/entities/active_verified/warehouse_operator'); //Dev
    return response.data;
  } catch (error)
  {
    if (error.response && error.response.data && error.response.data.errors)
    {
      throw error.response.data.errors;
    } else
    {
      throw error;
    }
  }
};


